import "./style.css";
export default function Loading() {
    return (
        <div className="container-loading-component-page">
            <div className="spinner-page spinner-large-page">
                <div className="spinner-blade-page"></div>
                <div className="spinner-blade-page"></div>
                <div className="spinner-blade-page"></div>
                <div className="spinner-blade-page"></div>
                <div className="spinner-blade-page"></div>
                <div className="spinner-blade-page"></div>
                <div className="spinner-blade-page"></div>
                <div className="spinner-blade-page"></div>
            </div>
        </div>
    );
}
